<template>
  <b-form-group :label-for="field.key" :class="field.class">
    <b-row
      v-for="(condition, idx) in value"
      :key="`condition-${idx}`"
      class="dynamic-group"
    >
      <b-col cols="12">
        <h4>
          {{
            $i18n.locale == "en"
              ? condition.lotteryName
              : condition.lotteryNameKh
          }}
        </h4>
      </b-col>
      <b-col md="6">
        <b-form-group :label-for="`comission-2d-${idx}`">
          <validation-provider
            #default="{ errors }"
            :vid="`comission-2d-${idx}`"
            :name="$t('field.commissionPercentage2')"
            :rules="`required|decimal:2|max_value:100`"
          >
            <label> {{ $t("field.commissionPercentage2") }} </label>
            <b-form-input
              v-model="condition.commissionNumTwo"
              :disabled="disabled"
              :placeholder="$t('field.commissionPercentage2')"
              :name="`comission-2d-${idx}`"
              :class="errors.length > 0 ? 'is-invalid' : null"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('field.commissionTypeTwo')">
          <validation-provider
            #default="{ errors }"
            :vid="`comission-type-two-${idx}`"
            :name="$t('field.commissionTypeTwo')"
            :rules="``"
          >
            <b-form-radio-group
              class="button-checkbox"
              v-model="condition.commissionTypeTwo"
              :disabled="disabled"
              :name="`comission-type-two-${idx}`"
              button-variant="outline-primary"
              buttons
            >
              <b-form-radio :value="1" buttons>
                <span>
                  {{ $t("field.includeCommission") }}
                </span>
              </b-form-radio>
              <b-form-radio :value="2" buttons>
                <span>
                  {{ $t("field.excludeCommission") }}
                </span>
              </b-form-radio>
              <b-form-radio :value="3" buttons>
                <span>
                  {{ $t("field.excludeCommissionCutFee") }}
                </span>
              </b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label-for="`comission-3d-${idx}`">
          <validation-provider
            #default="{ errors }"
            :vid="`comission-3d-${idx}`"
            :name="$t('field.commissionPercentage3')"
            :rules="`required|decimal:2|max_value:100`"
          >
            <label> {{ $t("field.commissionPercentage3") }} </label>
            <b-form-input
              v-model="condition.commissionNumThree"
              :disabled="disabled"
              :placeholder="$t('field.commissionPercentage3')"
              :name="`comission-3d-${idx}`"
              :class="errors.length > 0 ? 'is-invalid' : null"
              autocomplete="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group :label="$t('field.commissionTypeThree')">
          <validation-provider
            #default="{ errors }"
            :vid="`comission-type-three-${idx}`"
            :name="$t('field.commissionTypeThree')"
            :rules="``"
          >
            <b-form-radio-group
              class="button-checkbox"
              v-model="condition.commissionTypeThree"
              :disabled="disabled"
              :name="`comission-type-three-${idx}`"
              button-variant="outline-primary"
              buttons
            >
              <b-form-radio :value="1" buttons>
                <span>
                  {{ $t("field.includeCommission") }}
                </span>
              </b-form-radio>
              <b-form-radio :value="2" buttons>
                <span>
                  {{ $t("field.excludeCommission") }}
                </span>
              </b-form-radio>
              <b-form-radio :value="3" buttons>
                <span>
                  {{ $t("field.excludeCommissionCutFee") }}
                </span>
              </b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BButton,
  BFormRadioGroup,
  BFormRadio,
} from "bootstrap-vue";
import NSingleSelect from "@/components/NSingleSelect";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BFormRadioGroup,
    BFormRadio,
    NSingleSelect,
  },
  props: {
    value: {},
    field: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>